@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300&display=swap');

.desktop-header{
    width: 100%;
    height: 100px;
    background-color: #000;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 2rem 9%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
}

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo h4{
    color: #fff;
    font-family: "Rowdies";
    font-size: 20px;
}

.logo img{
    height: 70px;
    padding-right: 10px;
    border-radius: 50%;
}

.sections{
    margin-left: 470px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sections .socialMediaA img{
    height: 35px;
    padding-left: 10px;
}

.sections .parts .sectionsA{
    text-decoration: none;
    padding: 5px;
    color: #fff;
    font-size: 20px;
    font-family: "Rowdies";
}

.sections .parts .mintB{
    color: #fbb037;
}

@media screen and (max-width: 768px) {
    /* For mobile phones: */
    .logo h4{
        font-size: 12px;
    }
    
    .logo img{
        height: 45px;
    }
    
    .sections{
        margin-left: 33px;
    }

    .sections .socialMediaA img{
        height: 20px;
        padding-left: 5px;
    }
    
    .sections .parts .sectionsA{
        text-decoration: none;
        padding: 2px;
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    /* For mobile phones: */
    .desktop-header{
        width: 110%;
        margin-left: -20px;
    }

    .logo h4{
        font-size: 10px;
    }
    
    .logo img{
        height: 35px;
    }
    
    .sections{
        margin-left: 80px;
    }

    .sections .socialMediaA img{
        height: 15px;
    }
    
    .sections .parts .sectionsA{
        padding: 1.8px;
    }
}

@media screen and (max-width: 380px) {
    /* For mobile phones: */
    .desktop-header{
        width: 110%;
        margin-left: -20px;
    }
    
    .logo h4{
        font-size: 10px;
    }

    .sections{
        margin-left: 83px;
    }
    
    .logo img{
        height: 25px;
    }
    
    .sections .parts .sectionsA{
        padding: 1.5px;
        font-size: 10px;
    }
}
