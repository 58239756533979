@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300&display=swap');

.mint{
    padding-top: 100px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 800px;
}

.action p{
    font-family: "Rowdies";
    color: #d3d3d3;
    font-size: 50px;
    margin-left: 180px;
}

.action .mintBttn{
    border: none;
    background-color: #fbb037;
    color: #000;
    padding: 10px;
    width: 200px;
    border-radius: 10px;
    font-family: "Rowdies";
    font-size: 30px;
    cursor: pointer;
    margin-left: 320px;
    margin-top: 25px;
}

.action .connectBttn{
    border: none;
    background-color: #242424;
    color: #fbb037;
    padding: 10px;
    width: 350px;
    border-radius: 10px;
    font-family: "Rowdies";
    font-size: 30px;
    cursor: pointer;
    margin-left: 230px;
    margin-top: 25px;
}

.action .mintingBttn{
    display: flex;
}

.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 25px;
    margin-top: 15px;
    background-color: #000;
    color: #000;
    animation: dotElastic 1s infinite linear;
  }
  
  .dot-elastic::before, .dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dotElasticBefore 1s infinite linear;
  }
  
  .dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dotElasticAfter 1s infinite linear;
  }
  
  @keyframes dotElasticBefore {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dotElastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dotElasticAfter {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .allMintedAction p{
    font-family: "Rowdies";
    color: #d3d3d3;
    font-size: 50px;
    margin-left: 100px;

  }

  .viewUs{
    display: inline-block;
    margin-left: 20px;
  }

  .openSeaBttn{
    background-color: #2081E2;
    border: none;
    width: 200px;
    padding: 10px;
    font-size: 30px;
    font-family: 'Rowdies';
    border-radius: 10px;
    margin-left: 240px;
    margin-top: 30px;
  }

  .openSeaBttn a{
    text-decoration: none;
    color: #fff;
  }

@media screen and (max-width: 768px) {
    /* For mobile phones: */
    .mint{
        padding-top: 150px;
        height: 600px;
        flex-direction: column;
    }

    .gif img{
        width: 300px;
        height: 400px;
    }

    .action p{
        font-size: 18px;
        margin-left: 30px;
    }

    .action .connectBttn{
        width: 220px;
        font-size: 18px;
        margin-left: 15px;
    }

    .action .mintBttn{
        width: 150px;
        font-size: 20px;
        margin-left: 40px;
    }

    .dot-elastic {
        margin-left: 25px;
        margin-top: 10px;
      }

    .allMintedAction p{
      font-size: 15px;
      margin-left: 20px;
    }
    
    .viewUs{
      margin-left: 8px;
    }
    
    .openSeaBttn{
      width: 100px;
      padding: 5px;
      font-size: 15px;
      margin-top: 10px;
      margin-left: 40px;
    }
}

@media screen and (max-width: 400px) {
  /* For mobile phones: */
  .gif img{
    width: 280px;
    height: 380px;
  }
  .action p{
    font-size: 15px;
    margin-left: 15px;
  }
  .action .connectBttn{
    width: 150px;
    font-size: 15px;
  }
  .action .mintBttn{
    width: 120px;
    font-size: 18px;
    margin-left: 25px;
  }
  .dot-elastic {
    margin-left: 20px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 380px) {
  /* For mobile phones: */
  .gif img{
    width: 280px;
    height: 380px;
  }
  .action p{
    font-size: 15px;
    margin-left: 20px;
  }
  .action .connectBttn{
    margin-left: 15px;
    width: 150px;
    font-size: 15px;
}

.action .mintBttn{
    width: 120px;
    font-size: 18px;
    margin-left: 30px;
}

.dot-elastic {
    margin-left: 20px;
    margin-top: 7px;
  }

  .allMintedAction p{
    font-size: 15px;
    margin-left: 20px;
  }

  .viewUs{
    margin-left: 8px;
  }

  .openSeaBttn{
    width: 100px;
    padding: 5px;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 40px;
  }
}