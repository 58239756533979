@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300&display=swap');

.assets{
    padding-top: 100px;
    background-color: #000;
    width: 100%;
    height: 500px;
}

.assets .assets-header{
    color: #4594e2;
    text-align: center;
    font-family: "Rowdies";
}

.swiper{
    padding-top: 20px;
    padding-left: 20px;
    height: 450px;
    width: 100%;
    cursor: grab;
}

.swiper .swiper-wrapper{
    transition-timing-function : linear;
}

.slide img{
    height: 350px;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    /* For mobile phones: */
    .assets{
        height: 450px;
    }

    .assets .assets-header{
        font-size: 20px;
    }

    .slide img{
        height: 300px;
        border-radius: 10px;
    }
}

