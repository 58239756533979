html{
  scroll-behavior: smooth;
}

body{
  background-color: #000;
}

body::-webkit-scrollbar{
  display: none;
}

.hr{
  margin-bottom: 50px;
}

.footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .VCA{
  margin-bottom: 50px;
  padding-right: 10px;
  margin-left: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.VCA p{
  margin-left: 110px;
  padding-bottom: 10px;
}

.VCA .address{
  color: #4594e2;
  margin-left: 2px;
  text-decoration: none;
}

.openSeaLogo{
  margin-bottom: 40px;
  margin-right: 10px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  /* For mobile phones: */
  .footer{
    font-size: 10px;
  }
  
  .VCA p{
    margin-left: 80px;
  }

  .openSeaLogo{
    margin-bottom: 45px;
    height: 40px;
  }
}

@media screen and (max-width: 380px) {
  /* For mobile phones: */
  .footer .VCA{
    margin-bottom: 50px;
    padding-right: 10px;
    margin-left: 10px;
  }
  
  .VCA p{
    margin-left: 70px;
  }

  .openSeaLogo{
    margin-bottom: 40px;
    height: 30px;
  }
}

